@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  option {
    font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply font-normal bg-white text-gray-darkest;
  }
  p, .body1 {
    @apply text-base font-normal;
  }

  label {
    @apply text-gray-darkest;
  }

  h1, .h1 {
    @apply text-4xl font-bold;
  }
  h2, .h2 {
    @apply text-3xl font-bold;
  }
  h3, .h3 {
    @apply text-2xl font-bold;
  }
  h4, .h4 {
    @apply text-xl font-bold;
  }
  h5, .h5 {
    @apply text-lg font-bold;
  }
  h6, .h6 {
    @apply text-base font-bold leading-tight;
  }

  .body2 {
    @apply text-sm font-normal;
  }

  .subtitle1 {
    @apply text-base leading-[1.75] font-bold;
  }

  .subtitle2 {
    @apply text-xs font-bold;
  }

  .caption {
    @apply text-base font-normal leading-5 tracking-wide;
  }

  .over-line {
    @apply text-sm font-normal leading-4 tracking-wider uppercase;
  }
}

.rich-content * {
  @apply text-xs
}

@layer components {
  .card {
    @apply overflow-hidden bg-white rounded-lg shadow;
  }
  /* Styling evaluator stars */
  .blue-stars .MuiRating-icon svg,
  .blue-stars .MuiRating-iconEmpty svg {
    @apply w-10 h-10 text-blue;
  }
  .gray-stars .MuiRating-icon svg,
  .gray-stars .MuiRating-iconEmpty svg {
    @apply w-10 h-10 text-gray;
  }
  .green-stars .MuiRating-icon svg,
  .green-stars .MuiRating-iconEmpty svg {
    @apply w-10 h-10 text-green;
  }
  .small-stars .MuiRating-icon svg,
  .small-stars .MuiRating-iconEmpty svg {
    @apply w-6 h-6 text-gray-dark;
  }
  .blue-stars > div,
  .gray-stars > div,
  .green-stars > div,
  .small-stars > div {
    margin-bottom: -5px;
  }

  .datepicker-input::-webkit-calendar-picker-indicator {
    @apply absolute top-0 left-0 w-full h-full p-0 m-0 cursor-pointer;
  }

  .chart-card .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick-line {
    visibility: hidden;
  }

  .input {
    @apply p-3 text-base font-semibold border rounded outline-none text-blue border-blue ring-offset-0 focus:ring-1 ring-blue-dark placeholder-blue-light;
  }

  .label {
    @apply text-lg font-bold text-gray-darkest;
  }

  .bt {
    @apply h-9 inline-flex items-center justify-center text-center rounded text-lg px-2 py-1 font-bold leading-6 tracking-wide uppercase;
  }

  .disabled {
    @apply border-gray-300 bg-gray-lightest text-gray-dark cursor-not-allowed;
  }

  .bt-contained {
    @apply bg-gradient-to-r from-[#7493CB] to-[#9B84BF] text-white shadow-md transition hover:bg-gradient-to-r hover:from-[#43669A] hover:to-[#6C578F] hover:shadow-lg disabled:from-[#dee1e4] disabled:to-[#dee1e4] disabled:text-gray disabled:shadow-none disabled:hover:from-[#dee1e4] disabled:hover:to-[#dee1e4];
  }

  .bt-outlined {
    @apply border border-blue text-blue hover:bg-[#6a97ce]/5 disabled:bg-[#dee1e4] disabled:text-gray disabled:hover:bg-[#dee1e4] disabled:border-gray;
  }

  .bt-blue {
    @apply border border-blue hover:bg-blue-dark bg-blue text-white shadow-md transition disabled:bg-gray-lightest disabled:text-gray disabled:shadow-none disabled:hover:bg-gray-lightest disabled:border-none;
  }

  .bt-text {
    @apply text-blue hover:bg-[#6a97ce]/5 disabled:bg-[#dee1e4] disabled:text-gray disabled:hover:bg-[#dee1e4];
  }

  .link {
    @apply font-bold text-blue no-underline;
  }
  
  .ai-button {
    @apply flex justify-center items-center h-auto px-[10px] py-[6px] rounded bg-gradient-to-r from-[#43669A] to-[#6C578F] text-lg font-bold uppercase text-white hover:text-[#C6CAFF] transition hover:from-[#474C78] hover:to-[#474C78] disabled:to-white shadow-md hover:shadow-lg disabled:from-white disabled:text-gray-dark disabled:border disabled:border-gray-dark;
  }

  #uploods > div > div {
    background-image: none !important;
  }

  .header-card {
    @apply bg-gradient-to-r from-[#7692CA]/50 via-[#7692CA]/50 to-[#BC9EC7] p-4
  }

  .fix-ql-editor-area .ql-editor {
    min-height: calc(5*1.5rem);
  }

  .form-helper-text {
    @apply font-normal text-base text-gray-darker mt-1;
  }

  .stepper-label {
    @apply text-base font-bold leading-4;
  }

  .chip-gray {
    @apply w-fit !bg-gray-lightest !px-3 py-1 !text-gray-darker text-base rounded-xl shadow-sm
  }
}

@layer utilities {
  .bg-vibe-background {
    background-image: url("../public/background_image.svg");
  }
  .radial-gradient {
    background: radial-gradient(
      2714.48% 445.24% at 194.53% 192.86%,
      #bc9ec7 0%,
      rgba(118, 146, 202, 0.433774) 48.56%,
      rgba(118, 146, 202, 0.433774) 100%
    );
  }
  .linear-gradient {
    @apply bg-gradient-to-r from-[rgba(118,146,202,0.43)] via-[rgba(118,146,202,0.43)] to-[rgba(188,158,199,1)];
  }

  .avatar-gradient-filter {
    @apply bg-gradient-to-br from-[rgba(106,151,206,0.5)] to-[rgba(239,97,164,0.5)]
  } 

  .avatar-gray-filter {
    @apply bg-gray opacity-70 bg-blend-lighten
  }
}

.MuiInputBase-root.Mui-focused, .MuiInputBase-root.Mui-focused * {
  box-shadow: none !important;
}

.override-dropzone .MuiSvgIcon-colorSecondary {
  color: #5F91CC !important;
}

.custom-autocomplete .MuiInput-underline::before {

  border-bottom: 0 !important;
}

.custom-autocomplete input {
  padding: 0 !important;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  color: #969696;
}

.custom-autocomplete-warning input::placeholder {
  opacity: 1 !important;
  color: #D6394C !important
}

.fc-header-toolbar {
  color: #5F91CC !important;
  font-size: xx-small;
  justify-content: end !important;
  padding: 0 !important;
  margin: 0 !important;  
}

.fc-button-primary   {
  background-color: white !important;
  border: none !important;
  color: #5F91CC !important;
  font-size: medium !important;
  font-weight: bolder !important;
  margin-top: -2px !important;
  padding: 0px 1rem 0px 1rem !important;
}

.fc-button-primary:focus {
  box-shadow: none !important;
}

.fc-sticky {
  color: #2D2D2C !important;
}

.fc-day-sun, .fc-day-sat {
  background-color: #EEEEEE;
  opacity: 0.3;
}

.fc-myTodayButton-button {
  font-size: 14px !important;
  margin-right: 16px !important; 
  padding-right: 32px !important;
  border-right: 1px solid #5F91CC !important;
}

.fc-scrollgrid {
  border: none !important;
}

.fc-resource-timeline-divider {
  visibility: hidden !important;
  width: 0px !important;
}

.fc th {
  border-right: none !important;
}

.fc td {
  border-right: none !important;
}

.fc-event {
  margin-top: 2px !important;
}

.fc-scrollgrid-section-header {
  background: white;
  position: sticky;
  top: 3rem;
  z-index: 1000;
}

.radio {
  @apply h-0 w-0 cursor-pointer opacity-0 absolute
}

.customRadioButton {
  @apply border-2 border-spacing-0 border-gray-darker flex items-center justify-center transition-all rounded-full h-5 w-5
}

.customRadioButton::after {
  @apply transition-all absolute h-0 w-0 bg-black rounded-full content-['']
}

.radio:checked ~ .customRadioButton {
  @apply  border-black
}

.radio:checked ~ .customRadioButton::after {
  @apply h-[12px] w-[12px]
}

details[open] .summary-arrow {
    @apply -rotate-180;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
